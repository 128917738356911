import React from "react";
import { ThumbsUp } from "lucide-react";

const CustomSlide = ({ slide }) => {
  return (
    <div className="position-relative d-flex align-items-center justify-content-center w-100 h-100">
      <img
        src={slide.src}
        alt={slide.alt || ""}
        className="mw-100 mh-100"
        style={{ objectFit: "contain" }}
      />
      {slide.liked && (
        <div className="position-absolute" style={{ top: "0", right: "2rem" }}>
          <ThumbsUp
            fill="#00a499"
            style={{ width: "1.5rem", height: "1.5rem" }}
          />
        </div>
      )}
    </div>
  );
};

export default CustomSlide;
