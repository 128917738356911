import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import useFetch from "use-http";
import { useSearchParams } from "react-router-dom";
import { MainContext } from "../Providers/MainContext";
import useFetchConfig from "../Hooks/useFetchConfig";
import useFilterStore from "../Hooks/useFilterStore";
import useRedirectStore from "../Hooks/redirectStore";
import LoadingSpinner from "./Metric/LoadingSpinner";

export default function LoginRedirect() {
  const fetchConfig = useFetchConfig();
  const { get, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirect");
  const { customer } = useContext(MainContext);
  const { resetAllFilters } = useFilterStore();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const from = location.state?.from;
  const { hasRedirected, markRedirectAttempted } = useRedirectStore();

  useEffect(() => {
    if (customer?.id) {
      let intendedPath = null;

      if (redirectTo) {
        intendedPath = redirectTo;
      } else if (from) {
        intendedPath = from;
      }

      if (intendedPath) {
        // Check if this path has already been attempted
        if (hasRedirected(intendedPath)) {
          // (`Redirect to "${intendedPath}" has already been attempted. Navigating to /home.`);
          navigate("/home", { replace: true });
          setLoading(false);
          return;
        }

        // Mark this path as attempted
        markRedirectAttempted(intendedPath);

        // Attempt to redirect
        if (intendedPath.startsWith("/api/reports/")) {
          const absoluteUrl = `${window.location.origin}${intendedPath}`;
          // (`Performing full page redirect to ${absoluteUrl}`);
          window.location.href = absoluteUrl;
        } else {
          // (`Navigating internally to ${intendedPath}`);
          navigate(intendedPath, { replace: false });
        }
      } else {
        fetchDashboards(customer);
      }
    } else {
      setLoading(false);
    }
  }, [customer, redirectTo, from, navigate, hasRedirected, markRedirectAttempted]);

  const fetchDashboards = async (c) => {
    setLoading(true);
    let data = await get(`/api/customer/${c.id}/dashboards`);
    if (response.ok) {
      if (data && data.length === 1) {
        // isFiltersRetained is set to true
        resetAllFilters(true);
        navigate(`/dashboard/${data[0].id}`);
      } else {
        navigate("/home");
      }
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <Card>
        <LoadingSpinner text="Loading home..." />
      </Card>
    );
  } else {
    return (
      <Row>
        <Col>
          <br />
          <h1 className="text-white text-center">
            Thank you for registering with Impact!
          </h1>
          <p className="text-white text-center">
            You’re now ready to be assigned to Dashboards. Please let the Impact
            team know that you are registered, and they will assign you to the
            relevant dashboards.
          </p>
        </Col>
      </Row>
    );
  }
}
