import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Row,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import useFetch from "use-http";

import FilterBadges from "../../Components/FilterBadges";
import FiltersModal from "../../Components/FiltersModal";
import useFetchConfig from "../../Hooks/useFetchConfig";
import useFilterStore from "../../Hooks/useFilterStore";
import useOverlay from "../../Hooks/useOverlay";
import { MainContext } from "../../Providers/MainContext";
import { useTranslation } from "react-i18next";
import MetricBox from "../Metric/MetricBox";
import { pollTaskStatus } from "../../Utils/utilFunctions";
import { fetchAndDownloadXlsx } from "../../Utils/utilFunctions";

// import { Sunrise, Sunset, Sun, Moon } from 'react-bootstrap-icons';

export default function DashboardPage() {
  const fetchConfig = useFetchConfig();
  const { get, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const { customer } = useContext(MainContext);
  const filterParams = useFilterStore();
  const { isFiltersRetained, resetAllFilters, setGroupBy } = useFilterStore();
  const { handleToggleFilterModal, getUrlParams } = useFilterStore();
  const overlayParams = useOverlay();
  const { id } = useParams();
  const [dashboard, setDashboard] = useState(null);
  const [panels, setPanels] = useState({});
  const [presets, setPresets] = useState({});
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // group_by should not be passed to l1 from l2 or vice versa
    setGroupBy("");
    if (id) {
      fetchDashboard();
    }
    if (isFiltersRetained === false) {
      resetAllFilters();
    }
  }, [id]);

  useEffect(() => {
    fetchPanels();
    fetchPresets();
  }, [id]);

  const fetchDashboard = async () => {
    let data = await get(`/api/dashboard/${id}`);
    if (response.ok) {
      setError(null);
      setDashboard(data);
    } else {
      setError("Dashboard not found");
    }
  };
  const fetchPanels = async () => {
    let data = await get(`/api/dashboard/${id}/metrics`);
    if (response.ok) {
      setError(null);
      setPanels(data);
    } else {
      setError("Error loading metric panels.");
    }
  };
  const fetchPresets = async () => {
    let data = await get(`/api/dashboard/${id}/presets`);
    if (response.ok) {
      setError(null);
      setPresets(data);
    } else {
      setError("Error loading date-range presets.");
    }
  };


  const fetchDashboardExport = async () => {
    setIsLoading(true);
    let data = await get(`/api/dashboard/${id}/exports/xlsx?${getUrlParams()}`);
    if (response.ok) {
      const task_id = data.task_id;
      const datePart = new Date().toISOString().replaceAll(":", ".");
      const filename = `dash_${dashboard.title.split(" ").join("-").toLowerCase()}_${datePart}.xlsx`;
      pollTaskStatus(task_id, get, fetchAndDownloadXlsx, filename, response, setError, setIsLoading);  
    }
  };

  const _renderPanelMetrics = (panelMetrics) => {
    return (
      <Row>
        {panelMetrics.map((metric, index) => (
          <MetricBox
            key={metric.id}
            metricId={metric.id}
            index={index}
            sidebar={false}
            getUrlParams={getUrlParams}
          />
        ))}
      </Row>
    );
  };

  return (
    <>
      {dashboard && (
        <Container fluid className="mx-2">
          {error && <p className="text-danger">{error}</p>}
          <br />
          <Row className="justify-content-center">
            <Col />
            <Col>
              <h1 className="text-light text-center">
                {t("Dashboard")}: {dashboard.title}
              </h1>
              <h4 className="text-light text-center">
                {dashboard.description}
              </h4>
              <br />
              {panels && panels.length === 0 && (
                <p className="text-light text-center">Metrics loading...</p>
              )}
            </Col>
            <Col className="d-flex justify-content-start">
              <FiltersModal
                overlayParams={overlayParams}
                id={id}
                customer={customer}
                setError={setError}
                isDashboard={true}
                metric={presets}
                setGraph={null}
              />
              <OverlayTrigger
                delay={{ hide: 400 }}
                overlay={
                  <Tooltip className="primary-tooltip">
                    This may take some time. Don't worry.
                  </Tooltip>
                }
              >
                <div className="my-auto mx-1">
                  <Button
                    onClick={fetchDashboardExport}
                    className="btn btn-primary text-white "
                    disabled={isLoading}
                  >
                    Export
                  </Button>
                </div>
              </OverlayTrigger>
              <Button
                onClick={handleToggleFilterModal}
                className="btn btn-primary text-white my-auto"
              // style={{ height: "70px" }}
              >
                {t("Filter")}
              </Button>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="auto">
              <FilterBadges filterParams={filterParams} t={t} />
            </Col>
          </Row>
          <Row>
            {panels &&
              panels.panels &&
              panels.panels
                .sort((a, b) => a.index - b.index)
                .map((panel) => (
                  <Col x={12} md={6} lg={6} xl={4} key={panel.title}>
                    <Row>
                      <Col className="mx-4">
                        <h4
                          className="text-light"
                          dangerouslySetInnerHTML={{
                            __html: t(`${panel.title}`),
                          }}
                        />
                        <p></p>
                        {_renderPanelMetrics(panel.metrics)}
                      </Col>
                    </Row>
                  </Col>
                ))}
          </Row>
        </Container>
      )}
    </>
  );
}
