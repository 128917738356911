import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import useFetch from "use-http";
import useFetchConfig from "../../Hooks/useFetchConfig";
import { useTranslation } from "react-i18next";
import useFilterStore from "../../Hooks/useFilterStore";
import GenericFilters from "../../Components/GenericFilters";
import {
  handleFilterGroupSwitchChange,
  processFilterGroups,
} from "../../Utils/FilterGroupUtils";
import FilterBadges from "../../Components/FilterBadges";

export function SetEOSRules({
  dSurvey,
  dashboardId,
  eosModalOpen,
  setEosModalOpen,
  onUpdatePrimary,
  maxPriority,
}) {
  const fetchConfig = useFetchConfig();
  const { get, patch, post, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const [error, setError] = useState(null);
  const [primaryStart, setPrimaryStart] = useState(dSurvey?.start_date || "");
  const [primaryEnd, setPrimaryEnd] = useState(dSurvey?.end_date || "");
  const [priority, setPriority] = useState(maxPriority + 1);
  const [locations, setLocations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [locationsReady, setLocationsReady] = useState(false);
  const [rolesReady, setRolesReady] = useState(false);  
  const [updateLocRoles, setUpdateLocRoles] = useState(false);
  const filterParams = useFilterStore();
  const {
    filterGroups,
    setFilterGroups,
    selectedFilterGroups,
    setSelectedFilterGroups,
    filterGroupSwitchStates,
    setFilterGroupSwitchStates,
  } = filterParams;

  const { t } = useTranslation();
  const onSwitchChange = (value, isChecked, label) => {
    handleFilterGroupSwitchChange(
      value,
      isChecked,
      label,
      setSelectedFilterGroups,
      setFilterGroupSwitchStates,
    );
  };
  useEffect(() => {
    if (dSurvey) {
      setPrimaryStart(dSurvey?.start_date || "");
      setPrimaryEnd(dSurvey?.end_date || "");
    }
    // Probably don't need to do this every time the survey changes; once per dash should suffice?
    if (dashboardId) {
      fetchLocations("get").then(() => setLocationsReady(true));
      fetchRoles("get").then(() => setRolesReady(true));
    }
  }, [dSurvey]);

  useEffect(() => {
    setPriority(maxPriority + 1)
  }, [maxPriority]);

  useEffect(() => {
    if (updateLocRoles) {
      fetchLocations("post").then(() => setLocationsReady(true));
      fetchRoles("post").then(() => setRolesReady(true));
      setUpdateLocRoles(false);
    }
  }, [updateLocRoles]);
  
  useEffect(() => {
    if (locationsReady && rolesReady) {
      const data = [
        ...(locations || []).map(location => ({ value: location.location_name, label: "location" })),
        ...(roles || []).map(role => ({ value: role.role_name, label: "role" }))
      ];
      processFilterGroups(data, setFilterGroups, setFilterGroupSwitchStates);
      // Toggle switch for each location based on presence in dSurvey.locations
      if (dSurvey && dSurvey.locations) {
        locations.forEach(location => {
          const isInDSurvey = dSurvey.locations.some(dLoc => dLoc.location_name === location.location_name);
          onSwitchChange(location.location_name, isInDSurvey, "location");
        });
      }

      // Toggle switch for each role based on presence in dSurvey.roles
      if (dSurvey && dSurvey.roles) {
        roles.forEach(role => {
          const isInDSurvey = dSurvey.roles.some(dRole => dRole.role_name === role.role_name);
          onSwitchChange(role.role_name, isInDSurvey, "role");
        });
      }
      setLocationsReady(false);
      setRolesReady(false);
    }
  }, [locationsReady, rolesReady]);

  const fetchLocations = async (httpMethod) => {
    const data = await {
      get: get,
      post: post,
    }[httpMethod](`/api/dashboard/${dashboardId}/crm-locations`);

    if (response.ok) {
      setLocations(data);
      setError(null);
    } else {
      setError("Locations not found (or set).");
    }
  };

  const fetchRoles = async (httpMethod) => {
    const data = await {
      get: get,
      post: post,
    }[httpMethod](`/api/dashboard/${dashboardId}/crm-roles`);

    if (response.ok) {
      setRoles(data);
      setError(null);
    } else {
      setError("Roles not found (or set).");
    }
  };

  const handleLocRoleUpdate = () => setUpdateLocRoles(true);
  const handleSave = async () => {
    // Make an API call here to update the primary_start and primary_end dates
    // Assuming the API call is successful, trigger the callback
    let payload = { priority: priority };
    if (primaryStart !== "") payload.start_date = primaryStart;
    if (primaryEnd !== "") payload.end_date = primaryEnd;
    if (selectedFilterGroups["location"]) payload.locations = selectedFilterGroups["location"];
    if (selectedFilterGroups["role"]) payload.roles = selectedFilterGroups["role"];
    let data = await patch(`/api/dashboard-survey/${dSurvey.id}`, payload);
    if (response.ok) {
      setError(null);
      onUpdatePrimary(data);
      setEosModalOpen(false);
    } else {
      setError(data?.error || "Something went wrong with the update.");
    }
  };
  return (
    <Modal show={eosModalOpen} onHide={() => setEosModalOpen(false)}>
      <Modal.Header className="bg-light">
        <Modal.Title>
          {`Edit when survey ${dSurvey?.survey.form_id} is EOS form:`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        {error && <div className="alert alert-danger">{error}</div>}{" "}
        {/* Display error message */}
        <Row>
          <Col className="d-flex flex-column justify-content-end">
            <Form.Group className="mb-3" controlId="start">
              <Form.Label>{t("Start")}</Form.Label>
              <Form.Control
                type="date"
                value={primaryStart}
                onChange={(e) => setPrimaryStart(e.target.value)}
                className="bg-light"
              />
            </Form.Group>
          </Col>
          <Col className="d-flex flex-column justify-content-end">
            <Form.Group className="mb-3" controlId="end">
              <Form.Label>{t("End")}</Form.Label>
              <Form.Control
                type="date"
                value={primaryEnd}
                onChange={(e) => setPrimaryEnd(e.target.value)}
                className="bg-light"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <FilterBadges
            filterParams={filterParams}
            t={t}
          />
        </Row>
        <GenericFilters
          filterGroups={filterGroups}
          filterGroupSwitchStates={filterGroupSwitchStates}
          onSwitchChange={onSwitchChange}
          t={t}
        />
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <div className="me-auto">
          <Button variant="cyan" onClick={handleLocRoleUpdate}>
            Update Locations & Roles
          </Button>
        </div>
        <Button variant="secondary" onClick={() => setEosModalOpen(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Update Rule
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
