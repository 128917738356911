import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const LoadMoreTrigger = ({ onLoadMore }) => {
  const loadMoreRef = useRef();

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        onLoadMore();
      }
    }, option);

    const currentRef = loadMoreRef.current; // Capture the current value of the ref
    if (currentRef) observer.observe(currentRef);
    return () => {
      if (currentRef) observer.unobserve(currentRef); // Use the captured value here
    };
  }, [onLoadMore]);

  return <div ref={loadMoreRef} />;
};

LoadMoreTrigger.propTypes = {
  onLoadMore: PropTypes.func.isRequired,
};

export default LoadMoreTrigger;
