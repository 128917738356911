// PhotoActionsDropdown.jsx
import React from "react";
import { MoreHorizontal, ThumbsUp, Trash2 } from "lucide-react";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import LoadingSpinner from "../../Metric/LoadingSpinner";

const PhotoActionsDropdown = ({
  photo,
  onLike,
  onUnlike,
  onRotate,
  onDelete,
  rotating,
  canImpersonate,
}) => {
  return (
    <Dropdown autoClose="outside">
      <Dropdown.Toggle
        variant="dark"
        size="sm"
        className="opacity-75 p-1"
      >
        {photo.liked ? (
          <ThumbsUp className="me-2" fill="#00a499" />
        ) : (
          <MoreHorizontal className="h-4 w-4" />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {rotating ? (
          <Dropdown.Item onClick={(e) => e.stopPropagation()}>
            <LoadingSpinner text="Rotating..." size="md" />
          </Dropdown.Item>
        ) : (
          <>
            {photo.liked ? (
              <Dropdown.Item
                className="d-flex align-items-center"
                onClick={(e) => {
                  e.stopPropagation();
                  onUnlike(photo.id);
                }}
              >
                <ThumbsUp className="me-2" fill="#de6e86" />
                Unlike
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                className="d-flex align-items-center"
                onClick={(e) => {
                  e.stopPropagation();
                  onLike(photo.id);
                }}
              >
                <ThumbsUp className="me-2" fill="#00a499" />
                Like
              </Dropdown.Item>
            )}
            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
                onRotate(photo, 90);
              }}
            >
              Rotate +90°
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
                onRotate(photo, -90);
              }}
            >
              Rotate -90°
            </Dropdown.Item>
            {canImpersonate && (
              <Dropdown.Item
                className="d-flex align-items-center text-danger"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(photo.id);
                }}
              >
                <Trash2 className="me-2" />
                Delete
              </Dropdown.Item>
            )}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

PhotoActionsDropdown.propTypes = {
  photo: PropTypes.object.isRequired,
  onLike: PropTypes.func.isRequired,
  onUnlike: PropTypes.func.isRequired,
  onRotate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  rotating: PropTypes.bool.isRequired,
  canImpersonate: PropTypes.bool.isRequired,
};

export default PhotoActionsDropdown;
