import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import {
  OpsTable,
  MiniButton,
  TitleButton,
  HeadlineButtons,
  ExportButton,
  FiltersButton,
  Cell,
} from "./utils";
import {
  dbToLocale,
  formatStdDateLocaleTime,
} from "../../../Utils/utilFunctions";

export default function EosComplianceCard({
  data,
  filterParams,
  handleExport,
  isLoading,
  refreshEosCompliance,
}) {
  const {
    groupBy,
    setGroupBy,
    selectedFilterGroups,
    setSelectedFilterGroups,
    handleToggleFilterModal,
  } = filterParams;
  const rows = data.value.rows;
  if (rows === undefined) return <></>;
  const headline = data.value.headline;
  const columns = data.value.columns;
  const primaryButtonColumns = ["Total Number Required", "Missing"];
  const secondary = data.value.secondary;
  const secondaryButtonColumns = ["n/a"];
  const cellToColor = (row, column) => {
    const variantNum = "Exactly one Response";
    const variantDenom = "Total Number Required";
    const calc = row[variantDenom]
      ? (1.0 * row[variantNum]) / row[variantDenom]
      : 0.5;
    let variantColor = "primary";
    if (calc < 0.5) {
      variantColor = "danger";
    } else if (calc < 0.8) {
      variantColor = "purple";
    }
    return variantColor;
  };
  const buttonConfig = [
    {
      content: `EOS-compliance: ${headline.actual_value} / ${headline.size} = ${headline.value}%`,
      tooltip: "% of shifts with at least 1 EOS submission.",
    },
  ];
  const getCellClass = (responses) => {
    const baseClasses = " bg-opacity-50 text-end";
    if (!responses || responses.length === 0) {
      return "bg-danger" + baseClasses;
    }
    if (responses.length === 1) {
      return "bg-primary" + baseClasses;
    }
    return "bg-warning" + baseClasses;
  };
  const assignCell = (row, column, rowIndex, args) => {
    const key = `${rowIndex}-${column}`;
    const buttonColumns = args["buttonColumns"];
    let value = row[column];
    if (column === "Shift Date") {
      value = formatStdDateLocaleTime(row[column], dbToLocale(row["db"]));
    }
    if (buttonColumns.includes(column) && (!(groupBy === column))) {
      const handleButton = () => {
        let selected = { ...selectedFilterGroups };
        if (buttonColumns.includes(groupBy)) {
          selected["job_code"] = [row["Job Number"]];
        } else {
          selected["db"] = [row["Db"]];
        }
        setGroupBy(column);
        setSelectedFilterGroups(selected);
      };
      return (
        <MiniButton
          key={key}
          row={row}
          column={column}
          updateConfig={[
            { key: "job_code", source: "Job Number" },
            { key: "db", source: "Db" },
          ]}
          cellToColor={cellToColor}
          setGroupBy={setGroupBy}
          selectedFilterGroups={selectedFilterGroups}
          setSelectedFilterGroups={setSelectedFilterGroups}
          isLoading={isLoading}
          handleButton={handleButton}
        />
      );
    } else if (column === "Db") {
      return (
        <Cell
          key={key}
          value={
            <a
              href={`https://portal-${row["Db"]}.weareelevate.global/checkins`}
              target="_blank"
              rel="noreferrer"
            >
              {row["Db"]}
            </a>
          }
          tooltip={column}
        />
      );
    } else if (column === "Responses") {
      const responses = row["Responses"];
      if (!responses || responses.length === 0) {
        // No responses
        return (
          <Cell
            key={key}
            clss="text-muted"
            value=""
            tooltip="No responses available"
          />
        );
      } else {
        // Multiple responses
        const responseList = responses.map((response, idx) => (
          <div key={`response-${idx}`}>
            <strong>({idx + 1})</strong>
            <br />
            name: {response.responder_name || "N/A"}
            <br />
            location: {response.tf_location || "N/A"}
            <br />
            response ID: {response.response_id || "N/A"}
            <br />
            date: {response.response_date || "N/A"}
            <br />
            <br />
          </div>
        ));
        return (
          <Cell
            key={key}
            // clss="text-primary"
            value={<>{responseList}</>}
            tooltip={<>{responseList}</>}
          />
        );
      }
    } else if (
      row[column] !== null &&
      (typeof row[column] === "number" ||
        row[column].endsWith("%") ||
        row[column] === "n/a")
    ) {
      return <Cell key={key} clss="text-center" value={value} tooltip={column} />;
    } else if (column === "Assignment ID" && row["Responses"]) {
      return (
        <Cell
          key={key}
          clss={getCellClass(row["Responses"])}
          value={
            <a
              href={`https://portal-${row["Db"]}.weareelevate.global/assignments/${row["Assignment ID"]}`}
              target="_blank"
              rel="noreferrer"
            >
              {row["Assignment ID"]}
            </a>
          }
          tooltip={column}
        />
      );
    } else {
      return <Cell key={key} clss="" value={value} tooltip={column} />;
    }
  };

  const renderTooltip = () => (
    <Tooltip className="primary-tooltip">
      This will refresh CRM data from CRM db(s) & update with any TF data in IMPACT db.
    </Tooltip>
  );

  const header = () => {
    return (
      <Row className="d-flex justify-content-between align-items-center">
        <Col>
          {groupBy ? (
            <TitleButton
              title={data.name}
              deleteKeys={["job_code", "db"]}
              setGroupBy={setGroupBy}
              setSelectedFilterGroups={setSelectedFilterGroups}
              isLoading={isLoading}
            />
          ) : (
            <h5>{data.name}</h5>
          )}
        </Col>
        <Col className="mx-auto"></Col>
        <Col xs="auto">
          <HeadlineButtons buttonConfig={buttonConfig} isLoading={isLoading} />
          <ExportButton isLoading={isLoading} handleExport={handleExport} />
          <FiltersButton
            isLoading={isLoading}
            handleToggleFilterModal={handleToggleFilterModal}
          />
          <OverlayTrigger overlay={renderTooltip()}>
            <Button
              className="text-white mx-1"
              onClick={refreshEosCompliance}
              disabled={isLoading}
            >
              Refresh
            </Button>
          </OverlayTrigger>
        </Col>
      </Row>
    );
  };

  return (
    <Card>
      <CardHeader>{header()}</CardHeader>
      <Card.Body>
        <OpsTable
          columns={columns}
          rows={rows}
          assignCell={assignCell}
          args={{ buttonColumns: primaryButtonColumns }}
        />
        {secondary && (
          <OpsTable
            columns={secondary.columns}
            rows={secondary.rows}
            assignCell={assignCell}
            args={{ buttonColumns: secondaryButtonColumns }}
          />
        )}
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
}
