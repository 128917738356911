import { create } from "zustand";
import { SPLIT_CHARS } from "../config";

// import { persist, createJSONStorage } from 'zustand/middleware';

const initialState = {
  disabled: true,
  overlayOptions: [],
  overlaySelection: "",
  groupBy: "",
  startdate: "",
  endDate: "",
  locations: new Set(),
  cities: new Set(),
  regions: new Set(),
  countries: new Set(),
  selectedLocations: new Set(),
  selectedCities: new Set(),
  selectedRegions: new Set(),
  selectedCountries: new Set(),
  brands: new Set(),
  categories: new Set(),
  products: new Set(),
  selectedBrands: new Set(),
  selectedCategories: new Set(),
  selectedProducts: new Set(),
  filterGroups: {},
  selectedFilterGroups: {},
  filterGroupSwitchStates: {},
  isFiltersRetained: true,
  likedFirst: false,
  getUrlParams: () => new URLSearchParams(), // Placeholder, will be updated by recomputeUrlParams
};

const recomputeUrlParamsFromState = (get) => {
  const {
    startdate,
    endDate,
    selectedLocations,
    selectedCities,
    selectedRegions,
    selectedCountries,
    selectedBrands,
    selectedCategories,
    selectedProducts,
    groupBy,
    selectedFilterGroups,
  } = get();

  let urlObj = {};

  const addToUrlObj = (key, values) => {
    const arr = Array.isArray(values) ? values : Array.from(values);
    if (arr.length > 0) {
      urlObj[key] = arr.join(SPLIT_CHARS);
    }
  };

  if (startdate) urlObj.start_date = startdate;
  if (endDate) urlObj.end_date = endDate;

  addToUrlObj("locations", selectedLocations);
  addToUrlObj("cities", selectedCities);
  addToUrlObj("regions", selectedRegions);
  addToUrlObj("countries", selectedCountries);
  addToUrlObj("brands", selectedBrands);
  addToUrlObj("categories", selectedCategories);
  addToUrlObj("products", selectedProducts);

  if (groupBy) urlObj.group_by = groupBy;

  Object.entries(selectedFilterGroups).forEach(([group, values]) => {
    const arr = Array.isArray(values) ? values : Array.from(values);
    if (arr.length > 0) {
      urlObj[group] = arr.join(SPLIT_CHARS);
    }
  });

  return new URLSearchParams(urlObj);
};

const useFilterStore = create(
  // persist(
  (set, get) => ({
    ...initialState,

    recomputeUrlParams: () => {
      const paramsString = recomputeUrlParamsFromState(get);
      // Update getUrlParams to a new function reference each time
      set({
        getUrlParams: () => new URLSearchParams(paramsString),
      });
    },

    setDisabled: (value) => {
      set({ disabled: value });
    },
    setOverlayOptions: (options) => {
      set({ overlayOptions: options });
    },
    setOverlaySelection: (selection) => {
      set({ overlaySelection: selection });
    },
    setGroupBy: (value) => {
      set({ groupBy: value });
      get().recomputeUrlParams();
    },
    setStartDate: (date) => {
      set({ startdate: date });
      get().recomputeUrlParams();
    },
    setEndDate: (date) => {
      set({ endDate: date });
      get().recomputeUrlParams();
    },

    setLocations: (locations) => {
      set({ locations: locations });
    },
    setCities: (cities) => {
      set({ cities: cities });
    },
    setRegions: (regions) => {
      set({ regions: regions });
    },
    setCountries: (countries) => {
      set({ countries: countries });
    },
    setSelectedLocations: (locations) => {
      set({ selectedLocations: locations });
      get().recomputeUrlParams();
    },
    setSelectedCities: (cities) => {
      set({ selectedCities: cities });
      get().recomputeUrlParams();
    },
    setSelectedRegions: (regions) => {
      set({ selectedRegions: regions });
      get().recomputeUrlParams();
    },
    setSelectedCountries: (countries) => {
      set({ selectedCountries: countries });
      get().recomputeUrlParams();
    },
    setBrands: (brands) => {
      set({ brands: brands });
    },
    setCategories: (categories) => {
      set({ categories: categories });
    },
    setProducts: (products) => {
      set({ products: products });
    },
    setSelectedBrands: (brands) => {
      set({ selectedBrands: brands });
      get().recomputeUrlParams();
    },
    setSelectedCategories: (categories) => {
      set({ selectedCategories: categories });
      get().recomputeUrlParams();
    },
    setSelectedProducts: (products) => {
      set({ selectedProducts: products });
      get().recomputeUrlParams();
    },

    setFilterGroups: (groups) => {
      set({ filterGroups: groups });
    },
    setSelectedFilterGroups: (groups) => {
      set({ selectedFilterGroups: groups });
      get().recomputeUrlParams();
    },
    setFilterGroupSwitchStates: (states) => {
      set({ filterGroupSwitchStates: states });
    },

    handleToggleFilterModal: () => {
      set((state) => ({ filterOpen: !state.filterOpen }));
    },
    handleIsFiltersRetained: () => {
      set((state) => ({ isFiltersRetained: !state.isFiltersRetained }));
    },
    handleToggleLikedFirst: () => {
      set((state) => ({ likedFirst: !state.likedFirst }));
    },

    resetAllFilters: (retainFilters = false) => {
      set({
        ...initialState,
        isFiltersRetained: retainFilters,
      });
      get().recomputeUrlParams();
    },
  }),
  // {
  //   name: 'filter-storage',
  //   storage: createJSONStorage(() => localStorage),
  //   partialize: (state) => ({
  //     startdate: state.startdate,
  //     endDate: state.endDate,
  //     // Add other properties you want to persist if needed
  //   }),
  // }
  // )
);

export default useFilterStore;
