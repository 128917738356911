// src/Hooks/redirectStore.js
import { create } from 'zustand';

const useRedirectStore = create((set, get) => ({
  attemptedRedirects: {},
  markRedirectAttempted: (path) =>
    set((state) => ({
      attemptedRedirects: {
        ...state.attemptedRedirects,
        [path]: true,
      },
    })),
  hasRedirected: (path) => !!get().attemptedRedirects[path],
  resetRedirects: () => set({ attemptedRedirects: {} }),
}));

export default useRedirectStore;
