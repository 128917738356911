import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, Table } from "react-bootstrap";

import { EditInsight } from "./EditInsight";
import { AddInsight } from "./AddInsight";

// Main AdminInsightsTable component
export default function AdminInsightsTable(props) {
  const { t } = useTranslation();
  const { insights: initialInsights, dashboardId } = props;
  // State to manage insights
  const [insights, setInsights] = useState(initialInsights);
  const [editInsight, setEditInsight] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [updatedInsights, setUpdatedInsights] = useState(null);
  const [maxPriority, setMaxPriority] = useState(null);

  // Update sortConfig to hold multiple keys
  const [sortConfig, setSortConfig] = useState([
    { key: "insight", direction: "asc" },
    { key: "priority", direction: "desc" },
  ]);

  useEffect(() => {
    setInsights(initialInsights);
  }, [initialInsights]);

  // UseEffect to update the insights once updatedInsights changes
  useEffect(() => {
    if (updatedInsights) {
      setInsights(updatedInsights);
    }
  }, [updatedInsights]);

  useEffect(() => {
    // Extract priorities and find the maximum value
    const maxPriority = Math.max(
      ...insights.map((insight) => insight.priority || 0),
    );
    setMaxPriority(maxPriority);
  }, [insights]);

  useEffect(() => {
    if (addModalOpen === false) {
      
    }
  }, [addModalOpen])

  const primaryRangeModal = (insight) => {
    setEditInsight(insight);
    setEditModalOpen(true);
  };

  // Modify the sortedInsights function to handle multiple sorting keys
  const sortedInsights = (insights) => {
    return [...insights].sort((a, b) => {
      for (let { key, direction } of sortConfig) {
        const aValue = a[key];
        const bValue = b[key];

        if (aValue < bValue) {
          return direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return direction === "asc" ? 1 : -1;
        }
        // If values are equal, move on to the next key in sortConfig
      }
      return 0; // All keys have been checked and are equal
    });
  };

  // Update requestSort to handle multiple keys
  const requestSort = (key) => {
    setSortConfig((currentConfig) => {
      const existingIndex = currentConfig.findIndex(
        (config) => config.key === key,
      );
      let newConfig;
  
      if (existingIndex >= 0) {
        const toggledDirection =
          currentConfig[existingIndex].direction === "asc" ? "desc" : "asc";
        
        // Remove the existing key from its current position
        const updatedConfig = [
          ...currentConfig.slice(0, existingIndex),
          ...currentConfig.slice(existingIndex + 1),
        ];
  
        // Add the key with the toggled direction to the start of the array
        newConfig = [
          { key, direction: toggledDirection },
          ...updatedConfig,
        ];
      } else {
        // If the key doesn't exist, add it to the start with ascending direction
        newConfig = [{ key, direction: "asc" }, ...currentConfig];
      }
  
      return newConfig;
    });
  };
  const renderedInsights = sortedInsights(insights);

  const renderSortableHeader = (label, columnKey) => {
    // Find the sort configuration for this column, if it exists
    const sortColumn = sortConfig.find((config) => config.key === columnKey);
    const sortIndicator = sortColumn
      ? sortColumn.direction === "asc"
        ? "▲"
        : "▼"
      : "";

    return (
      <th scope="col" onClick={() => requestSort(columnKey)}>
        {t(label)} {sortIndicator}
      </th>
    );
  };

  return (
    <Col className="mx-auto">
      <br />
      <Row>
        <Col className="mx-auto">
          <h1 className="text-light">{t("Insights")}</h1>
        </Col>
        <Col className="mx-auto">
          <Button
            onClick={(() => setAddModalOpen(true))}
            className="w-100"
          >
            {t("Add Insight")}
          </Button>
        </Col>
      </Row>
      <br />
      <EditInsight
        dashboardId={dashboardId}
        insight={editInsight}
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        onUpdatePrimary={setUpdatedInsights}
        maxPriority={maxPriority}
      />
      <AddInsight
        dashboardId={dashboardId}
        modalOpen={addModalOpen}
        setModalOpen={setAddModalOpen}
        onUpdatePrimary={setUpdatedInsights}
      />
      <Row>
        <Table hover className="rounded-3 overflow-hidden">
          <thead>
            <tr className="border-0">
              {renderSortableHeader("Insight", "insight")}
              {renderSortableHeader("Priority", "priority")}
            </tr>
          </thead>
          <tbody>
            {renderedInsights.map((insight) => (
              <tr key={insight.id} className="border-0">
                <td>{insight.insight}</td>
                <td>
                  <Button
                    variant="primary text-white"
                    className="w-100"
                    onClick={() => primaryRangeModal(insight)}
                  >
                    {insight.priority ?? "-"}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </Col>
  );
}
