import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import useFetch from "use-http";
import useFetchConfig from "../../Hooks/useFetchConfig";
import { useTranslation } from "react-i18next";

export function AddInsight({
  dashboardId,
  modalOpen,
  setModalOpen,
  onUpdatePrimary,
}) {
  const fetchConfig = useFetchConfig();
  const { post, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const [error, setError] = useState(null);
  const [insight, setInsight] = useState("");
  const [priority, setPriority] = useState(0);
  
  const { t } = useTranslation();

  const postInsight = async () => {
    const payload = {
      insight: insight,
      priority: priority,
    };
    let data = await post(`/api/dashboard/${dashboardId}/insights`, payload);
    if (response.ok) {
      if (data?.error) {
        setError(data.error);
      } else {
        setError(null);
        onUpdatePrimary(data);
        setModalOpen(false);  
      }
    } else {
      setError("Insight post failed.");
    }
  };

  return (
    <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
      <Modal.Header className="bg-light">
        <Modal.Title>
          {t(`Add Insight`)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        {error && <div className="alert alert-danger">{error}</div>}{" "}
        {/* Display error message */}
        <Row>
          <Col className="d-flex flex-column justify-content-end">
            <Form.Group className="mb-3" controlId="insight">
              <Form.Label>{t("Insight")}</Form.Label>
              <Form.Control
                type="textarea"
                value={insight}
                onChange={(e) => setInsight(e.target.value)}
                placeholder='e.g. "Change X was effective because we noticed Y increased by an average of Z for the 5 responses afterwards when compared with the 12 before.'
                className="bg-light"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex flex-column justify-content-end">
            <Form.Group className="mb-3" controlId="priority">
              <Form.Label>{t("Priority")}</Form.Label>
              <Form.Control
                type="number"
                value={priority}
                onChange={(e) =>
                  setPriority(parseInt(e.target.value, 10) || "")
                }
                className="bg-light"
                min="1"
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button variant="secondary" onClick={() => setModalOpen(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={postInsight}>
          {t("Add Insight")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
