// DeleteConfirmationModal.jsx
import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";

const DeleteConfirmationModal = ({
  show,
  onHide,
  onConfirm,
  title = "Delete Photo",
  body = "Are you sure you want to delete this photo?",
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="bg-light">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="bg-light">{body}</Modal.Body>

      <Modal.Footer className="bg-light">
        <Button variant="secondary" onClick={onHide}>
          No
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
};

export default DeleteConfirmationModal;
