import React, { useState, useEffect, useMemo } from "react";
import { Card } from "react-bootstrap";
import useFetch from "use-http";
import useFetchConfig from "../Hooks/useFetchConfig";
import {
  Button,
  Container,
  Col,
  Table,
  Modal,
  Row,
  FormSelect,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FilterInput from "../Components/FilterInput";
import useFilterStore from "../Hooks/useFilterStore";
import GenericFilters from "../Components/GenericFilters";
import FilterBadges from "../Components/FilterBadges";
import {
  handleFilterGroupSwitchChange,
  processFilterGroups,
} from "../Utils/FilterGroupUtils";
import LoadingSpinner from "./Metric/LoadingSpinner";
import { SPLIT_CHARS } from "../config";


function getFeeList(job) {
  const feeMap = {
    UK: [
      "£0-10k",
      "£10k-20k",
      "£20k-30k",
      "£30k-70k",
      "£70k-100k",
      "£100k-200k",
      "£200k+",
    ],
    US: [
      "$0-12.5k",
      "$12.5k-25k",
      "$25k-35k",
      "$35k-90k",
      "$90k-120k",
      "$120k-250k",
      "$250+",
    ],
    DE: [
      "€0-12k",
      "€12k-24k",
      "€24k-36k",
      "€36k-82.5k",
      "€82.5k-120k",
      "€120k-235k",
      "€235k+",
    ],
    FR: [
      "€0-12k",
      "€12k-24k",
      "€24k-36k",
      "€36k-82.5k",
      "€82.5k-120k",
      "€120k-235k",
      "€235k+",
    ],
  };
  if (job && job.db in feeMap) {
    return feeMap[job.db];
  } else {
    return feeMap["UK"];
  }
}

export default function JobPage() {
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const fetchConfig = useFetchConfig();
  const { get, patch, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const [error, setError] = useState(null);
  const [feeBracket, setFeeBracket] = useState(1);
  const [jobStatus, setJobStatus] = useState(null);
  const [currentJob, setCurrentJob] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [editStatusModalOpen, setEditStatusModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState("status");
  const [sortDirection, setSortDirection] = useState("desc");
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const filterParams = useFilterStore();
  const {
    filterGroups,
    setFilterGroups,
    selectedFilterGroups,
    setSelectedFilterGroups,
    filterGroupSwitchStates,
    setFilterGroupSwitchStates,
  } = filterParams;

  const onSwitchChange = (value, isChecked, label) => {
    handleFilterGroupSwitchChange(
      value,
      isChecked,
      label,
      setSelectedFilterGroups,
      setFilterGroupSwitchStates,
    );
  };

  const filteredJobs = useMemo(() => {
    return jobs.filter((job) => {
      // Check date filters
      const dateFilterPassed =
        (!startDateFilter ||
          Date.parse(job.start_date) >= Date.parse(startDateFilter)) &&
        (!endDateFilter ||
          Date.parse(job.end_date) <= Date.parse(endDateFilter));

      // Check selected filter groups
      const filterGroupPassed = Object.entries(selectedFilterGroups).every(
        ([label, selectedValues]) => {
          const jobValue = job[label];

          // Ensure the job's value for this label matches one of the selected values
          return (
            selectedValues.length === 0 || selectedValues.includes(jobValue)
          );
        },
      );
      return dateFilterPassed && filterGroupPassed;
    });
  }, [jobs, startDateFilter, endDateFilter, selectedFilterGroups]);

  // Sorting logic based on sortBy, sortDirection, and filteredJobs
  const sortedJobs = useMemo(() => {
    return [...filteredJobs].sort((a, b) => {
      if (!sortBy) return 0; // If no sorting, return as is

      let valA = a[sortBy];
      let valB = b[sortBy];
      // Handle the case where valA or valB is null or undefined
      if (valA === null || valA === undefined) {
        // Ascending: Move nulls to the end, Descending: Move nulls to the top
        return sortDirection === "asc" ? 1 : -1;
      }
      if (valB === null || valB === undefined) {
        // reverse of valA
        return sortDirection === "asc" ? -1 : 1;
      }

      // Handle the case where sorting involves strings
      if (typeof valA === "string") {
        valA = valA.toLowerCase();
        valB = valB.toLowerCase();
      }
      if (valA < valB) return sortDirection === "asc" ? -1 : 1;
      if (valA > valB) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });
  }, [filteredJobs, sortBy, sortDirection]);

  const fetchJobs = async () => {
    setTableLoading(true);
    const data = await get("/api/crm/");
    if (!response.ok) {
      setError("Error fetching jobs");
    } else {
      setJobs(data);
      setTableLoading(false);
      processFilterGroups(
        generateUniqueLabelValueArray(
          [
            "account_manager",
            "staffing_manager",
            "customer_name",
            "db",
            "job_code",
            "status",
          ],
          jobs,
        ),
        setFilterGroups,
        setFilterGroupSwitchStates,
      );
    }
  };

  const updateJob = async (id, jobStatus, feeBracket) => {
    setLoading(true);
    let res = await patch(`api/crm/${id}`, {
      status: jobStatus,
      fee_bracket: feeBracket,
    });
    if (!response.ok) {
      setError("Error Updating Dashboard");
    } else {
      setJobs(
        jobs.map((job) => {
          if (job.id === id) {
            return res;
          }
          return job;
        }),
      );
    }
    closeEditModal();
  };

  // const createFormId = async (id) => {
  //   let res = await post(`api/crm/${id}/form`);
  //   if (!response.ok) {
  //     setError('Error Creating Form');
  //   } else {
  //     setJobs(jobs.map((job) => {
  //       if (job.id === id) {
  //         return res;
  //       }
  //       return job
  //     }));
  //   }
  // }

  // const createDashboard = async (id) => {
  //   let res = await post(`api/crm/${id}/dashboard`);
  //   if (!response.ok) {
  //     setError('Error Creating Dashboard');
  //   } else {
  //     setJobs(jobs.map((job) => {
  //       if (job.id === id) {
  //         return res;
  //       }
  //       return job
  //     }));
  //   }
  // }
  function generateUniqueLabelValueArray(keys, data) {
    const uniquePairs = new Set();

    keys.forEach((key) => {
      data.forEach((item) => {
        const value = item[key] ?? null; // Use null if value is undefined
        const pairString = JSON.stringify({ label: key, value });
        uniquePairs.add(pairString); // Add serialized pair to Set
      });
    });

    // Convert each string back to an object
    const result = Array.from(uniquePairs).map((pairString) =>
      JSON.parse(pairString),
    );

    // Sort result by the order of keys, and alphabetically by value within each label
    result.sort((a, b) => {
      const labelIndexA = keys.indexOf(a.label);
      const labelIndexB = keys.indexOf(b.label);

      // Sort by label order based on keys
      if (labelIndexA !== labelIndexB) {
        return labelIndexA - labelIndexB;
      }

      // Sort alphabetically by value within the same label
      return (a.value ?? "").localeCompare(b.value ?? "");
    });

    return result;
  }

  useEffect(() => {
    fetchJobs();
  }, []);

  const _renderFilterModal = () => {
    return (
      <Modal show={filterOpen} onHide={() => setFilterOpen(false)}>
        <Modal.Header closeButton className="bg-light">
          <Modal.Title>{t("Filter")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <Container>
            <Row>
              <FilterInput
                label="Start Date"
                type="date"
                value={startDateFilter}
                onChange={setStartDateFilter}
              />
              <FilterInput
                label="End Date"
                type="date"
                value={endDateFilter}
                onChange={setEndDateFilter}
              />
            </Row>
            <Row>
              <GenericFilters
                filterGroups={filterGroups}
                filterGroupSwitchStates={filterGroupSwitchStates}
                onSwitchChange={onSwitchChange}
                t={t}
                displayOptionsFunc={_prettyJobStatus}
              />
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="bg-light">
          <Button
            variant="primary"
            className="text-white"
            onClick={() => setFilterOpen(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const openEditModal = (job) => {
    setCurrentJob(job);
    setJobStatus(job.status);
    setFeeBracket(job.fee_bracket);
    setEditStatusModalOpen(true);
    setLoading(false);
  };

  const closeEditModal = () => {
    setCurrentJob(null);
    setFeeBracket(null);
    setJobStatus(null);
    setEditStatusModalOpen(false);
    setLoading(false);
  };

  const _renderStatusModal = () => {
    const feeList = getFeeList(currentJob);

    const isSubmitDisabled = (jobStatus, fee_bracket) => {
      if (jobStatus === "PAID" && fee_bracket === null) {
        return true;
      }
      if (!jobStatus) {
        return true;
      }
      return false;
    };

    return (
      <>
        {error && <h1>{error}</h1>}
        {currentJob && (
          <Modal show={editStatusModalOpen} onHide={() => closeEditModal()}>
            <Modal.Header closeButton className="bg-light">
              <Modal.Title>Status</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-light">
              {/* renamed from Job Code to Job Number
              See the translation.json if you would like to rename on filter label.
              */}
              <h2>Job Number: {currentJob.job_code}</h2>
              <br />

              <h5>Status:</h5>
              <FormSelect
                className="bg-light"
                value={jobStatus}
                onChange={(event) => {
                  const value = event.target.value;
                  setJobStatus(value);
                  if (value !== "PAID") {
                    setFeeBracket(null);
                  }
                }}
              >
                <option value=""></option>
                <option value="INVALID">Invalid - 1CRM Test</option>
                <option value="PAID">Paid</option>
                <option value="UNPAID">Unpaid</option>
                <option value="CUSTOM">Custom Level 2/3</option>
              </FormSelect>
              <br />

              {jobStatus === "PAID" && (
                <>
                  <h5>Staffing budget total bracket:</h5>
                  <FormSelect
                    className="bg-light"
                    value={feeBracket}
                    onChange={(event) => setFeeBracket(event.target.value)}
                  >
                    <option value=""></option>
                    {feeList.map((bracket, index) => (
                      <option key={index} value={index + 1}>
                        {bracket}
                      </option>
                    ))}
                  </FormSelect>
                  <br />
                </>
              )}
              {!loading && (
                <Button
                  disabled={isSubmitDisabled(jobStatus, feeBracket)}
                  onClick={() =>
                    updateJob(currentJob.id, jobStatus, feeBracket)
                  }
                >
                  Set Details
                </Button>
              )}
              {loading && <span>This may take a minute</span>}
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  };

  const _prettyJobStatus = (status) => {
    switch (status) {
      case "INVALID":
        return "Invalid - 1CRM Test";
      case "PAID":
        return "Paid";
      case "UNPAID":
        return "Unpaid";
      case "CUSTOM":
        return "Custom Level 2/3";
      case null:
        return "Unassigned";
      default:
        return status;
    }
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      // Toggle sorting direction if the same column is clicked again
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Set new column to sort by and default to ascending
      setSortBy(column);
      setSortDirection("asc");
    }
  };
  const _renderTable = () => {
    if (tableLoading || !jobs)
      return (
        <Card>
          <LoadingSpinner text="Loading jobs..." />
        </Card>
      );
    return (
      <Table
        hover
        striped
        className="rounded-3 overflow-auto"
        size="sm"
        responsive="lg"
      >
        <thead>
          <tr className="border-0">
            <th onClick={() => handleSort("id")}>{t("id")}</th>
            <th onClick={() => handleSort("db")}>{t("db")}</th>
            <th onClick={() => handleSort("customer_name")}>
              {t("customer_name")}
            </th>
            <th onClick={() => handleSort("job_code")}>{t("job_code")}</th>
            <th onClick={() => handleSort("start_date")}>{t("date")}</th>
            <th onClick={() => handleSort("interal_job_name")}>
              {t("job_name")}
            </th>
            <th onClick={() => handleSort("account_manager")}>
              {t("account_manager")}
            </th>
            <th onClick={() => handleSort("staffing_manager")}>
              {t("staffing_manager")}
            </th>
            <th onClick={() => handleSort("status")}>{t("status")}</th>
            <th>{t("actions")}</th>
          </tr>
        </thead>
        <tbody key={sortedJobs}>
          {sortedJobs &&
            sortedJobs.map((job) => (
              <tr className="border-0" key={job.id}>
                <td>{job.id}</td>
                <td>{job.db}</td>
                <td>{job.customer_name}</td>
                <td>{job.job_code}</td>
                <td>
                  {job.start_date} &nbsp; {job.end_date}
                </td>
                <td>{job.internal_job_name}</td>
                <td>
                  {job.account_manager &&
                    job.account_manager.split(SPLIT_CHARS).map((manager, index) => (
                      <React.Fragment key={index}>
                        {manager}
                        {index < job.account_manager.split(SPLIT_CHARS).length - 1 && (
                          <br />
                        )}
                      </React.Fragment>
                    ))}
                </td>
                <td>
                  {job.staffing_manager &&
                    job.staffing_manager.split(SPLIT_CHARS).map((manager, index) => (
                      <React.Fragment key={index}>
                        {manager}
                        {index < job.staffing_manager.split(SPLIT_CHARS).length - 1 && (
                          <br />
                        )}
                      </React.Fragment>
                    ))}
                </td>
                <td>
                  <span>{_prettyJobStatus(job.status)}</span>
                </td>
                <td>
                  <Button
                    onClick={() => {
                      openEditModal(job);
                    }}
                  >
                    Update
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
  };

  return (
    <Col xs={11} className="mx-auto">
      {_renderStatusModal()}
      {_renderFilterModal()}

      <Row className="mt-4 mb-4">
        <Col xs={2} className="d-flex">
          <h2 className="text-white">Job List</h2>
        </Col>
        <Col
          xs={8}
          className="d-flex justify-content-center align-items-center"
        >
          <FilterBadges
            filterParams={filterParams}
            t={t}
            displayFunc={_prettyJobStatus}
          />
        </Col>
        <Col xs={2} className="d-flex justify-content-end">
          <Button
            onClick={() => fetchJobs()}
            className="mx-1"
            disabled={tableLoading}
          >
            Refresh
          </Button>
          <Button onClick={() => setFilterOpen(true)} className="mx-1">
            Filter
          </Button>
        </Col>
      </Row>

      <Row className="table-responsive">{_renderTable()}</Row>
    </Col>
  );
}
